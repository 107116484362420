import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import { SelectPlaylistsScreenshot, StatsScreenshot } from '../index/IndexMainFunctionality';

export const StatsUpselling: React.FC<{
  showTitle?: boolean;
}> = ({ showTitle = true }) => {
  const intl = useIntl();

  return (
    <Container>
      {showTitle && (
        <Row>
          <Col>
            <h2 className="smaller-header text-center my-5">
              {intl.formatMessage({
                id: 'web.stats.upselling.title',
                defaultMessage: 'Enter the music world with the Free Your Music app'
              })}
            </h2>
          </Col>
        </Row>
      )}
      <Row className="d-flex flex-row align-items-center justify-content-center">
        <Col xs={4}>
          <div className="px-5 mx-5 mx-md-0">
            <SelectPlaylistsScreenshot />
          </div>
          <h3 className="text-center mt-4">
            {intl.formatMessage({
              id: 'web.stats.upselling.transfer-music.title',
              defaultMessage: 'Transfer Your Music'
            })}
          </h3>
          <p className="text-center">
            {intl.formatMessage({
              id: 'web.stats.upselling.transfer-music.description',
              defaultMessage: 'Move your favorite playlists and albums between music services.'
            })}
          </p>
        </Col>
        <Col xs={4}>
          <div className="px-5 mx-5 mx-md-0">
            <StatsScreenshot />
          </div>
          <h3 className="text-center mt-4">
            {intl.formatMessage({
              id: 'web.stats.upselling.check-music.title',
              defaultMessage: 'Check Your Music'
            })}
          </h3>
          <p className="text-center">
            {intl.formatMessage({
              id: 'web.stats.upselling.check-music.description',
              defaultMessage: 'See your music stats from various streaming services.'
            })}
          </p>
        </Col>
      </Row>
    </Container>
  );
};
